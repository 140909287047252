.container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 1rem ;
    position: relative;
    background-color: #fff;
  }
  .title {
    font-size: x-large;
    font-weight: bold;
  }
  .subcontainer{
      display: flex;
  }
  .img {
    width: 250px;
    height: 250px;
  }
  .textcontainer {
    flex: 3;
    flex-direction: column;
    justify-items: center;
    line-height: 2rem;
  }

  .textcontainer ul li {
    font-size: medium;
    padding: 1rem 0;
  }
  
  .textcontainer ul {
    list-style: none;
    padding-left: 5%;
  }

  .textcontainer ul li::before {
    content: "\2022";
    color: #901844;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
  }
  @media screen and (max-width: 820px) {
    .title {
      font-size: large;
    }
    .textcontainer {
      line-height: 1.5rem;
    }
    .img {
      width: 200px;
      height: 200px;
    }
    .subcontainer{
      flex-direction: column;
  }
  }
  @media screen and (max-width: 420px) {
  .container{
    padding:0.5rem;
  }

  .textcontainer ul li {
    font-size: small;
  }
  .img {
    width: 150px;
    height: 150px;
  }

  }
  
  