.cardcontainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 1rem;
  }
  
  .textcontainer {
    width: auto;
    padding: 13px;
    font-size: medium;
  }
  .photo{
    width:300px;
    height: auto;
  }
  @media screen and (max-width: 820px) { 
    .photo{
      width: 400px;
      height: auto;
    }
  }
  @media screen and (max-width: 420px) { 
    .photo{
      width: 100%;
    }
    .textcontainer{
      font-size: small;
    }
  }
