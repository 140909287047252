.footer{
    width: 1200px;
    background-color: #383838;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    padding: 1rem 1rem;
}
.subcontainer{
    display: flex;
    position: relative;
}
.icon{
    padding:0 1.5rem;
    font-size: 1.8rem;
    color:#fff;
}
.link{
    padding:1rem 1.5rem;
    font-size: medium;
    color:#fff;
}
.line{
    border: 1px solid #FFFFFF;
    width:70%;
}
.text{
    padding-top:1rem;
    font-size: small;
    color:#fff;
}
.upicon{
    display: block;
    background-color: #fff;
    font-size: 1rem;
    color: black;
    padding: 0.3rem ;
    border-radius: 2px;
    margin-left: 2rem;
    margin-top:0.3rem;
}
@media screen and (max-width: 1200px) {
    .footer {
      width: 100%;
    }
    
  }
  @media screen and (max-width: 820px) {
    .icon{
        padding:0 1rem;
        font-size: 1.5rem;
        color:#fff;
    }
    .link{
        padding:0.5rem 1rem;
        font-size: small;
    }
    .line{
        width:90%;
    }
    .upicon{
        margin-left: 1rem;
        padding:0.2rem;
    }
  }