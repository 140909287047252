@import '~video-react/dist/video-react.css';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
.container {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: #f2f2f2;
}
/* override react elastic carousel styles */

.rec.rec-arrow.rec.rec-arrow-left:hover,
.rec.rec-arrow.rec.rec-arrow-right:hover,
.rec.rec-arrow.rec.rec-arrow-left:focus,
.rec.rec-arrow.rec.rec-arrow-right:focus {
  background-color: #1F3E07;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec.rec-dot {
  border: 2px #1F3E07 solid;
  box-shadow: none;
}

.rec.rec-dot:hover,
.rec.rec-dot:focus {
  box-shadow: none;
}

.rec.rec-dot_active {
  background-color: #1F3E07;
  box-shadow: none;
}

