
.container {
    width: 1200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .video{
    position: relative;
    height: 300px;
    width: 500px;
    padding: 10px 20px;
  }
  
  .title{
    font-size: x-large;
    font-weight: bold;
    padding:10px 0;
  }
 
  @media screen and (max-width: 1200px) {
    .container{
      width: 100%;
    }
    .video {
      width: 100%;
      height: auto;
    }
     
  }
  @media screen and (max-width: 820px) {
    .title{
      font-size: large;
    }
  }
 

  

 