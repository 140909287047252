.container {
    width: 1200px;
    display: flex;
    margin-top: 1px;
    padding: 1rem 1rem;
    position: relative;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: x-large;
    font-weight: bold;
  }
  .textcontainer {
    justify-items: center;
    line-height: 2rem;
    padding:15px 0;
  }
  .subcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .title {
    font-size:large;
}
.subcontainer{
  flex-direction: column;
}
}
@media screen and (max-width: 420px) {
  .textcontainer {
    font-size: small;
  }
}
