.container {
    width: 1200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 132px;
    position: relative;
   }
  .coverphoto{
    filter: brightness(50%);
    width:100%;
    height: 450px;
}
.covertext {
    position: absolute;
    top: 50%;
    left: 43%;
    color:#fff;
    font-size: 35px;
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .container {
        width: 100%;
    }
}
@media screen and (max-width: 820px) {
    .coverphoto {
        height: 380px;
    }
    .covertext{
        font-size: x-large;
        left: 40%;
    }
}
@media screen and (max-width: 530px) {
    .coverphoto {
        height: 200px;
    }
    .covertext{
        font-size: large;
        left: 40%;
    }
}
