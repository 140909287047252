.container {
    background-color: white;
    width: 1200px;
    margin-top: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  }
  
  
  .subcontainer{
    display: flex;
    width: 100%;
    justify-content: center;
  }
 
  .title{
    text-decoration: none;
    color:#969595;
    padding:1.5rem;
    font-size: x-large;
    font-weight: bold;
  }

  .active{
    color:black
  }
  
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
  }

  @media screen and (max-width: 820px) { 
    .title{
      font-size: large;
    }
  }
   
  @media screen and (max-width: 530px) {
    .container {
      margin-top: 160px;
    }
    .title{
      padding:0 1rem;
    }
  }
