.container {
    width: 1200px;
    display: flex;
    margin-top: 132px;
    padding: 1rem 1rem;
    position: relative;
    background-color: #fff;
  }

  
  .img {
    border-radius: 10px;
    width: 250px;
    height: 250px;
  }
  
  .textcontainer {
    flex: 3;
    line-height: 2rem;
    
  }

  
  .title {
    font-size: xx-large;
    font-weight: bold;
    padding: 1.5rem 0 ; 
    
  }
  
  .description {
    font-size: large;
    padding-right: 5px;
  }
  

  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
      padding: 1.8rem;
      flex-direction: column;
    }
    .container :nth-child(1) { order: 2; }
    .container :nth-child(2) { order: 1; }
    
  }
  @media screen and (max-width: 820px) {
    .container {
      margin-top:145px;
   }
    
    .title{
      font-size: x-large;
      padding: 1rem 0 ; 
    }
    .description {
      font-size: medium;
    }
    
    .img {
      width: 200px;
      height: 200px;
    }
    
  }
  @media screen and (max-width: 420px) {
    .title{
      font-size: large;
      padding: 0.5rem 0 ; 
    }
    .description {
      font-size: small;
    }
     
  .img {
    width: 150px;
    height: 150px;
  }
  .textcontainer {
    line-height: 1.5rem;
  }
 
  }
  