.container {
    width: 1200px;
    display: flex;
    padding: 1rem 1rem;
    position: relative;
    background-color: #fff;
  }

  
  .img {
    border-radius: 10px;
    width: 250px;
    height: 250px;
  }
  
  .textcontainer {
    flex: 3;
    line-height: 2rem;
    padding-right: 10px;
  }

  
  .title {
    font-size: x-large;
    font-weight: bold;
  }
  .postingdate{
    padding-bottom: 10px;
  }
  
  .description {
    font-size: medium;
    
  }
  
  .seemore{
    background-color: #1F3E07;
    font-size: medium;
    padding: 0.5rem 1rem;
    color:#fff;
    margin:10px 0px;
    width:fit-content;
    
  }
  

  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
      flex-direction: column;
    }
    .container :nth-child(1) { order: 2; }
    .container :nth-child(2) { order: 1; }

  }

  @media screen and (max-width: 820px) {
    .title{
      font-size: large;
    }
  }
  @media screen and (max-width: 420px) {
    .img {
      border-radius: 10px;
      width: 200px;
      height: 200px;
    }
    
    .title {
      font-weight: bold;
    }
    .postingdate{
      padding-bottom: 8px;
    }
    
    .description {
      font-size: small;
    }
  
    .seemore{
      font-size: small;
    }
  }

  