.container {
    width: 1200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    padding: 1rem 1rem;
   
  }
  .title{
    font-size: x-large;
    font-weight: bold;
    padding:10px 0;
  }
  .subtitle{
    padding-bottom: 15px;
  }
  .subcontainer{
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
    
  }
  .imagecontainer{
      position: relative;
      justify-content: center;
  }
  .img {
    width: 95%;
    height: auto;
    filter: brightness(50%);
  }
  .text{
    background-color: #fff;
    font-size: 1rem;
    padding: 1rem 2rem;
    position: absolute;
    top: 45%;
    left: 30%;
    text-align: center;
    border-radius: 5%;
    font-weight: bold;
    color:black;
  }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
  }
  @media screen and (max-width: 850px) {
    .text
    {
      padding:0.5rem 1rem;
      left: 32%;
    }
    .title{
      font-size: large;
    }
  }
  @media screen and (max-width: 620px) {
    .subcontainer{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
    .imagecontainer{
      width: 50%;
      height: auto;
    }
  }
  @media screen and (max-width: 420px) {
    .text
    {
      padding:0.5rem 1rem;
      left: 28%;
      font-size: 0.8rem;
    }
  }
  