.container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin-top: 132px;
    padding: 1rem;
    position: relative;
    background-color: #fff;
    align-items: center;
  }
  .img {
    width: 50%;
    height: auto;
  }
  .textcontainer {
    flex: 3;
    line-height: 2rem;
    padding: 1rem 1.5rem;
  }

  .title {
    font-size: x-large;
    font-weight: bold;
  }
  .postingdate{
    padding-bottom: 10px;
  }
  
  .description {
    font-size: medium;
  }
  @media screen and (max-width: 1200px) {
    .container {
        width: 100%;
    }
  }
  @media screen and (max-width: 820px) {
    .title{
      font-size: large;
    }
    .img {
      width: 80%;
    }
   
  }
  @media screen and (max-width: 530px) {
    .container{
      margin-top: 160px;
    }
  }
  @media screen and (max-width: 420px) {
    .description {
      font-size: small;
    }
    .postingdate{
      padding-bottom: 8px;
    }
    .img {
      width: 100%;
    }
   
  }