.container{
    padding:2rem;
    width: 1200px;
    display: flex;
    position: relative;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}
.motto{
    font-size: large;
}
@media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
}
@media screen and (max-width: 820px) {
.container{
    padding:1rem;
}
.motto{
    font-size: medium;
}
}

@media screen and (max-width: 420px) {
    .motto{
        font-size: small;
    }
}