.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  width: 1200px;
 
}

.navbar {
  background-color: #f2f2f2;
  width: 1200px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.navtitle {
  margin-left: 30px;
  margin-top: 35px;
  width: 200px;
  height: 100px;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  color: black;
  text-align: center;
}

.navlogo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.navtext {
  font-size: 12px;
  color: black;
  text-decoration: none;
}

.navmenu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 90vw;
  justify-content: end;
  margin-right: 2rem;
}

.navitem {
  display: flex;
  align-items: center;
  height: 80px;
}

.navlinks {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navlinks:hover {
  border-bottom: solid 1px #284511;
  transition: all 0.2s ease-out;
}

.menuicon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
  }

  .navbar {
    width: 100%;
    justify-content: space-between;
  }


  .navtitle {
    margin-left: 0;
    width: 150px;
  }

  .navmenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 130px;
    left: -100%;
    transition: all 0.5s ease;
  }

  .navmenu.active {
    background: white;
    left: 0px;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0;
  }

  .navlinks {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .navlinks:hover {
    border: none;
    background-color: #284511;
    color: white;
  }

  .navlogopostition {
    position: absolute;
    top: 1;
    left: 0;
  }

  .menuicon {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
  }
}


@media screen and (max-width: 530px) {
  .menuicon {
    top: 85px;
    font-size: 1.5rem;
  }
  .navmenu {
    top: 160px;
  }

} 