.container {
    width: 1200px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    padding: 1rem;
  }
  
  .title{
    font-size: x-large;
    font-weight: bold;
    padding:10px 0;
  }
  .photo{
    width:100%;
    height: auto;
    padding: 10px 30px;
  }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }

  }
  @media screen and (max-width: 820px) {
    .title{
      font-size: large;
    }
    
  }
  