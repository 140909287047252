.container {
  width: 1200px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
 }
.contactuscontainer{
  background-color: #F3F3F3;
  margin: 5rem 10rem;
  flex-direction: column;
  padding:2rem;
}
.title {
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 1rem;
}
.subcontainer{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.textcontainer {
  line-height: 1rem;
  width:auto;
}                    

.contact {
  display: flex;
  color: black;
  font-size: medium;
  font-weight: 500;
  padding: 1rem 0;
}
.contacttext{
  padding:0 0.5rem;
}
@media screen and (max-width: 1200px) {
  .container {
      width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .title{
    font-size: large;
  }
  .contact{
    padding:0.8rem 0;
  }
  .contactuscontainer{
    margin: 5rem ;
  }
  
}
@media screen and (max-width: 420px) {
 .contact{
   font-size: small;
   padding:0.5rem 0;
 }
 .subcontainer{
  justify-content: flex-start;
}
.contactuscontainer{
  margin: 1rem 4rem ;
}

}