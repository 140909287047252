.container {
    position: relative;
}

.subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 45%;
    left: 35%;
}

.covertext {
    color: #fff;
    font-size: 35px;
}

.subcovertext {
    color: #fff;
    font-size: 25px;
}

.coverphoto {
    filter: brightness(50%);
    width: 1200px;
    height: 620px;
}

.logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

@media screen and (max-width: 1200px) {
    .coverphoto {
        width: 100%;
    }
}

@media screen and (max-width: 820px) {
    .container {
       margin-top:137px;
    }
    
    .coverphoto {
        height: 480px;
    }

    .covertext {
        font-size: 30px;
    }

    .subcovertext {
        font-size: 20px;
    }

    .logo {
        width: 80px;
        height: 80px;
    }

    .subcontainer {
        left: 30%;
    }
}
@media screen and (max-width: 520px) {
    .covertext {
        font-size: 20px;
    }

    .subcovertext {
        font-size: 12px;
    }

    .logo {
        width: 50px;
        height: 50px;
    }

    .coverphoto {
        height: 250px;
    }
}