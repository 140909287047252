.container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding: 1rem;
    position: relative;
    background-color: #fff;
  }
  .title {
    font-size: x-large;
    font-weight: bold;
  }
  .textcontainer {
    line-height: 1rem;
  }
  .textcontainer ul li {
    font-size: medium;
    padding: 1rem 0;
  }
  
  .textcontainer ul {
    list-style: none;
    padding-left: 2%;
  }

  .textcontainer ul li::before {
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
    .textcontainer {
      line-height: 1.5rem;
    }
  }
  @media screen and (max-width: 820px) {
    .title {
      font-size:large;
  }
  }
  @media screen and (max-width: 420px) {
    .textcontainer ul li {
      font-size: small;
      padding: 0.5rem 0;
    }
  }
  
