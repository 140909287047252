.container {
    display: flex;
    flex-direction: column;
    margin-top: 132px;
    position: relative;
    background-color: #fff;
    padding:1rem 0;
  }
  @media screen and (max-width: 1200px) {
    .container{
      width: 100%
    }
  }
  @media screen and (max-width: 530px) {
    .container{
      margin-top: 160px;
    }
  }